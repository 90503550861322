import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { NavThemeProvider, AppOverlayProvider, AppProvider } from '@Context/AppContext';
import SEO from '@Components/SEO';
import Layout from '@Components/Layout';
import ContentfulModule from '@Src/components/ContentfulModule';
import { IHeroPage, IPageModule } from '@Types/ContentfulTypes';
import BlockLayout from '@Src/components/BlockLayout';
import '@GraphQL/fragments';

export interface Props {
	location: {
		pathname: string;
	};
	data: {
		contentfulProductPage: IHeroPage;
	};
}

const ProductPage = ({ data, location }: Props): ReactElement => {
	const { pageModules, title, heroModule, seoSettings } = data.contentfulProductPage;
	const menuTheme = heroModule?.theme;

	return (
		<AppProvider type="product" path={location.pathname}>
			<AppOverlayProvider>
				<NavThemeProvider theme={menuTheme}>
					<Layout>
						<SEO
							location={location.pathname}
							title={seoSettings?.title || title}
							description={seoSettings?.description}
							// meta={seoSettings?.metaTags}
							image={seoSettings?.image}
						/>
						<BlockLayout>
							{heroModule && <ContentfulModule key={heroModule.id} type={heroModule.type} data={heroModule} />}
							{pageModules &&
								pageModules.map(
									(module: IPageModule, index: number): ReactElement => (
										<ContentfulModule
											revealThreashold={index === 0 ? 0 : 0.2}
											key={module.id}
											type={module.type}
											data={module}
										/>
									)
								)}
						</BlockLayout>
					</Layout>
				</NavThemeProvider>
			</AppOverlayProvider>
		</AppProvider>
	);
};

export default ProductPage;

export const query = graphql`
	query($id: String!) {
		contentfulProductPage(id: { eq: $id }) {
			... on WPPageNode {
				...PageFields
				... on WPHeroPageNode {
					heroModule {
						... on WPPageModuleNode {
							...Hero
						}
					}
					pageModules {
						... on WPPageModuleNode {
							...InfographicsModule
							...TextBackgroundImageModule
							...TextImageModule
							...TextModule
							...MediaModule
							...VideoModule
							...RichTextModule
							...PersonalCards
							...ProductCards
							...ArticleCards
							...PartnerProducts
							...CtaModule
							...QuoteSlider
						}
					}
				}
			}
		}
	}
`;
